import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Col, Row, Container } from "react-bootstrap";
import {
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteDocument, getDocuments } from "../../services/document";
import SearchForm from "../../components/forms/SearchForm";
import ArticlesListItem from "../../components/ArticlesListItem";
import { getImageURL } from "../../helpers/image";
import NavPagination from "../../components/NavPagination";

const Documents = () => {
  const user = useSelector((state) => state.auth.user);

  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setDocuments] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const documentColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL({ path: row?.media, type: "document" })}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Порядок",
      selector: "priority",
      size: 80,
    },
    {
      name: "Дата обновления",
      cell: (row) => moment(row.updatedAt).fromNow(),
      size: 2,
    },
    {
      width: "120px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center pe-3">
          <Link
            disabled={selected.length > 0}
            to={"/article/" + row.id}
            className="me-4"
          >
            <IoCreateOutline size={22} />
          </Link>
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getDocuments(searchParams)
      .then((res) =>
        setDocuments((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setDocuments((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteDocument(id)
      .then(() => {
        getData();
        NotificationManager.success("Новость успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteDocument(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные новости успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при запросе"
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (categories.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Новости" />
      <section className="box">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5>Новости</h5>
          <Link to="/articles/create" className="btn-primary ms-5">
            Добавить новость
          </Link>
        </div>
        {/* <ul className="articles-list">
          {categories.items.map((item, index) => {
            return (
              <ArticlesListItem
                key={index}
                data={item}
                onDelete={() =>
                  setModalDelete({ show: !modalDelete.show, id: item.id })
                }
              />
            );
          })}
        </ul>
        <div className="py-2">
          <NavPagination />
        </div> */}

        <DataTable
          type="custom"
          data={categories.items}
          renderItem={(item, index) => (
            <ArticlesListItem
              key={index}
              data={item}
              onDelete={() =>
                setModalDelete({ show: !modalDelete.show, id: item.id })
              }
            />
          )}
          pagination={categories.pagination}
        />
        <CustomModal
          title={
            selected.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-primary"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить новость?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Documents;
