import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
// import Footer from '../components/Footer'
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import Menu from "../components/Menu";
import useIsMobile from "../hooks/useIsMobile";

const AppLayout = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isMobile = useIsMobile('991px');

  return (
    <>
      <ScrollRestoration />
      {isAuth ? (
        <Container>
          {
            (isMobile)
            ? <div className="layout-grid">
              <Header />
              <main className="admin">
                <Outlet />
              </main>
            </div>
            : <div className="layout-grid">
              <Menu />
              <Header />
              <main className="admin">
                <Outlet />
              </main>
            </div>
          }
        </Container>
      ) : (
        <Outlet />
      )}
      {/* <Footer /> */}
    </>
  );
};

export default AppLayout;
