import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiRoutes, BASE_URL } from "../config/api";
import socket from "../config/socket";
import { $api, $authApi } from "./index";
import { clearNotification, setNotification } from "../store/reducers/notificationSlice";

const login = createAsyncThunk("auth/login", async (payloads, thunkAPI) => {
  try {
    const response = await $api.post(apiRoutes.AUTH_LOGIN, payloads);
    if (response?.data?.member?.id) {
      socket.io.opts.query = {
        memberId: response.data.member.id,
      };
      socket.connect();
      socket.emit("member/add", {
        memberId: response.data.member.id,
      });
    }
    if (response?.data?.member?.task) {
      thunkAPI.dispatch(
        setNotification({
          task: response.data.member.task,
        })
      )
    }
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    socket.disconnect();
    const response = await $api.post(apiRoutes.AUTH_LOGOUT);
    thunkAPI.dispatch(clearNotification());
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const checkAuth = async () => {
  const response = await $authApi.post(apiRoutes.AUTH_CHECK);
  return response.data;
};

const refreshAuth = createAsyncThunk("auth/refresh", async (_, thunkAPI) => {
  try {
    const response = await $authApi.post(apiRoutes.AUTH_REFRESH);
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
const authRegister = async (payloads = {}) => {
  const response = await axios.post(
    `${BASE_URL}${apiRoutes.AUTH_REGISTRATION}`,
    payloads
  );
  return response?.data;
};

const authActivate = async (payloads = {}) => {
  const response = await axios.post(
    `${BASE_URL}${apiRoutes.AUTH_ACTIVATE}`,
    payloads
  );
  return response?.data;
};

const authPasswordRecovery = async (payloads = {}) => {
  const response = await axios.post(
    `${BASE_URL}${apiRoutes.AUTH_RECOVERY}`,
    payloads
  );
  return response?.data;
};

export {
  authRegister,
  authActivate,
  authPasswordRecovery,
  login,
  logout,
  checkAuth,
  refreshAuth,
};
