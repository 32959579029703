import "bootstrap/dist/css/bootstrap.min.css";
import React, { useLayoutEffect, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/styles/style.min.css";
import Loader from "./components/UI/Loader";
import socket from "./config/socket";
import AppRouter from "./routes/AppRouter";
import { checkAuth, logout } from "./services/auth";
import { setAuth, setUser } from "./store/reducers/authSlice";
import axios from "axios";
import { setSettings } from "./store/reducers/settingsSlice";
import { setNotification, updateNotification } from "./store/reducers/notificationSlice";
import { NotificationManager } from "react-notifications";

const App = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const notification = useSelector((state) => state.notification);
  console.log(notification)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    (async () =>
      await axios
        .get("https://ip.yooapp.ru")
        .then(
          ({ data }) => data?.ip && dispatch(setSettings({ ip: data.ip }))
        ))();

    if (localStorage.getItem("token")) {
      checkAuth()
        .then((data) => {
          if (data?.member?.task) {
            dispatch(
              setNotification({
                task: data.member.task,
              })
            )
          }

          data.member && dispatch(setUser(data.member));
          data.member && dispatch(setAuth(true));
          socket.io.opts.query = {
            memberId: data.member.id,
          };
          socket.disconnect().connect();
          socket.emit("member/add", {
            memberId: data.member.id,
          });
          socket.emit('createRoom', 'notifications')
        })
        .catch(() => dispatch(logout()))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      socket.io.opts.query = false
      socket.connect();
    }
    return () => {
      socket.off("connect");
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      socket.on("adminNotifications", (data) => {

        if (data) {
          NotificationManager.info("Новая задача");
          dispatch(updateNotification(data));

        }
      });

      return () => {
        socket.off("adminNotifications");
      };
    }
  }, [isAuth]);

  if (loading) {
    return <Loader full />;
  }

  return <AppRouter />;
};
export default App;
