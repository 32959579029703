import React, { useCallback, useLayoutEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getPayment } from "../../services/invoice";
import { customPrice } from "../../helpers/product";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { moduleRatePrice } from "../../helpers/module";

const Payment = () => {
  const { user, brand } = useSelector(
    ({ auth: { user }, brand: { active } }) => ({
      user,
      brand: active,
    })
  );

  const [modalDelete, setModalDelete] = useState(false);
  const [addInvoiceModal, setAddInvoiceModal] = useState(false);
  const [modules, setModules] = useState({
    loading: true,
    items: [],
  });

  const calculTotal = useCallback(() => {
    let total = 0;
    modules.items.map((e) => {
      let price = moduleRatePrice({
        module: e.module,
        relationModule: e,
        brand,
      });
      total += price.value > 0 ? price.value * e.count : 0;
    });
    return total;
  }, [modules]);

  const total = calculTotal();

  const [selected, setSelected] = useState([]);

  const moduleColumns = [
    {
      name: "#",
      cell: (row, index) => index,
      size: 1,
    },
    {
      name: "Описание",
      size: 4,
      cell: (row) => row?.module?.title ?? row.title ?? "Названия нет",
    },
    {
      name: "Кол-во",
      width: "100px",
      selector: "count",
    },
    {
      name: "Соимость",
      size: 2,
      cell: (row) =>
        moduleRatePrice({ module: row.module, relationModule: row, brand })
          ?.value ?? "Не указано",
    },
    {
      name: "Сумма",
      size: 2,
      cell: (row) =>
        customPrice(
          row?.price > 0
            ? row.price * row.count
            : row?.module?.price
            ? row.module.price * row.count
            : 0
        ),
    },
    {
      size: 2,
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="p-2">
          <a
            className="btn btn-sm btn-danger-outline"
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            Отключить
          </a>
        </div>
      ),
    },
  ];

  const getData = async () => {
    getPayment()
      .then((res) => {
        if (res) {
          let array = {
            month: [],
            one: [],
          };
          res.forEach((e) => {
            if (e.module.type == "one") {
              array.one.push(e);
            } else {
              array.month.push(e);
            }
          });

          setModules((prev) => ({
            ...prev,
            loading: false,
            items: [...array.month, ...array.one],
          }));
        }
      })
      .finally(() => setModules((prev) => ({ ...prev, loading: false })));
  };

  const onDelete = useCallback((id) => {
    deleteStatus(id)
      .then(() => {
        getData();
        NotificationManager.success("Статус успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteStatus(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные услуги успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, []);

  if (modules.loading) {
    return <Loader full />;
  }

  if (!modules.items || modules.items.length === 0) {
    return (
      <Container fluid={true}>
        <Meta title="Оплата услуг" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>На данный момент у васнет подключенных услуг</h3>
        </Info>
      </Container>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Оплата услуг" />
      <section className="box">
      <h3 className="mb-4">Оплата услуг</h3>
      <Row>
        <Col md={2}>
          <div className="payment flex-column justify-content-center my mb-3">
            <h4>{customPrice(user.cash)}</h4>
            <p>Баланс</p>
          </div>
        </Col>
        <Col md={2}>
          <div className="payment flex-column justify-content-center pay mb-3">
            <h4>{customPrice(total)}</h4>
            <p>К оплате</p>
          </div>
        </Col>
        <Col md={3}>
          <Link
            onClick={() => setAddInvoiceModal(!addInvoiceModal)}
            className="payment flex-row align-items-center add mb-3"
          >
            <img src="/images/invoice.svg" height={40} className="me-3" />
            <span>Сформировать счет</span>
          </Link>
        </Col>
        <Col>
          <Link
            to="/invoices"
            className="payment flex-row align-items-center list mb-3"
          >
            <img src="/images/payment.svg" height={40} className="me-3" />
            <span>Счета и оплаты</span>
          </Link>
        </Col>
        <Col>
          <Link
            to="/modules"
            className="payment flex-row align-items-center service mb-3"
          >
            <img src="/images/service.svg" height={40} className="me-3" />
            <span>Добавить услугу</span>
          </Link>
        </Col>
      </Row>
      <Card>
        <DataTable
          lite
          columns={moduleColumns}
          onChange={(items) => setSelected(items)}
          data={modules.items}
          headClassName="light"
        />
        <Card.Footer className="d-flex px-3 flex-column align-items-end">
          <Input placeholder="Введите промокод" />
          <p className="mt-3">Сумма {customPrice(total)}</p>
          <p className="mt-2 fw-5 fs-11">
            Итого <b>{customPrice(total)}</b>
          </p>
        </Card.Footer>
      </Card>
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить отчет(-ы)?
      </CustomModal>
      <CustomModal
        show={addInvoiceModal}
        setShow={setAddInvoiceModal}
        title="Сформировать счет"
        size="lg"
        footer={
          <>
            <Button
              type="button"
              className="btn-default"
              onClick={() => setAddInvoiceModal(!addInvoiceModal)}
            >
              Отмена
            </Button>
            <Button type="button" className="btn-primary">
              Сохранить
            </Button>
          </>
        }
      ></CustomModal>
      </section>
    </Container>
  );
};

export default Payment;
