import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Badge, Col, Container } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Loader from "../../components/UI/Loader";
import Chat from "../../components/chat";
import socket from "../../config/socket";
import { deliveryData, paymentData } from "../../helpers/order";
import {
  createMessage,
  createMessageGeneral,
  getDialogs,
  getMessages,
  getMessagesGeneral,
} from "../../services/message";
import { getImageURL } from "../../helpers/image";

const DialogItem = memo(({ item, active }) => {
  return (
    <Link
      to={"/dialogs/" + item.id}
      state={{ dialogId: item.id }}
      className={"chats-list-item" + (item.id == active ? " active" : "")}
    >
      <div className="img">
        {item?.from?.media || item?.to?.media ? (
          <div className="d-flex flex-row align-items-center">
            <div>
              {item?.from?.media ? (
                <img
                  src={getImageURL({ path: item?.from?.media, type: "user" })}
                  width={20}
                />
              ) : (
                <img src="/images/avatar.png" width={20} />
              )}
            </div>
            <div className="list-avatar-right">
              {item?.to?.media ? (
                <img
                  src={getImageURL({ path: item?.to?.media, type: "user" })}
                  width={20}
                />
              ) : (
                <img src="/images/avatar.png" width={20} />
              )}
            </div>
          </div>
        ) : (
          <img src="/images/avatar.png" width={40} />
        )}

        <div
          className={item?.user?.online?.status ? "ind online" : "ind"}
        ></div>
      </div>
      <div className="text">
        <div className="d-flex align-items-baseline justify-content-between">
          {item?.memberId ? (
            <h6>
              {item?.from?.firstName ? item.from.firstName : item.from.nickname}
            </h6>
          ) : (
            <h6>
              {item?.from?.firstName ? item.from.firstName : item.from.nickname}{" "}
              с {item?.to?.firstName ? item.to.firstName : item.to.nickname}
            </h6>
          )}
          <time>{moment(item.messages[0].createdAt).format("kk:mm")}</time>
        </div>
        <div className="last-user">
          <HiArrowLongRight />
          <span className="ms-2">{item?.message?.text ?? "Нет сообщений"}</span>
        </div>
      </div>
    </Link>
  );
});

const OrderItem = memo((item) => {
  return (
    <Link
      to={"/order/" + item.id}
      className="dialog-order d-block p-2 px-3 border-top"
    >
      <div className="d-flex justify-content-between aling-items-center">
        <div>
          <b className="fs-09">
            {moment(item.createdAt).format("DD.MM.YYYY kk:mm")}
          </b>
        </div>
        <div className="fs-07 text-muted d-flex align-items-center justify-content-end">
          {item?.statuses[0]?.status?.name ?? "Нет статуса"}
        </div>
      </div>
      <div className="d-flex justify-content-between aling-items-center">
        <div>
          <p className="d-block text-nowrap text-muted fs-08">
            id {item.id} - {paymentData(item.payment).text} -{" "}
            {deliveryData(item.delivery).text}
          </p>
          {item.delivery == "delivery" && (
            <p className="d-block text-nowrap text-muted fs-08">
              {item.street} {item.home}
            </p>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <IoChevronForwardOutline color="#999" size={20} />
        </div>
      </div>
    </Link>
  );
});

const Dialogs = () => {
  const { dialogId } = useParams();
  const { state } = useLocation();
  // const timer = useRef(0);
  // const message = useSelector((state) => state.notification.message);

  const { control, reset, setValue } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      id: state?.dialogId ?? dialogId,
    },
  });

  const data = useWatch({ control });
  const [search, setSearch] = useState('');
  const [print, setPrint] = useState(false);

  const [dialogs, setDialogs] = useState({
    loading: true,
    items: [],
  });

  const [messages, setMessages] = useState({
    loading: true,
    items: [],
  });

  const onLoadDialogs = () => {
    getDialogs({ search: search })
      .then((res) =>
        setDialogs((prev) => ({
          ...prev,
          loading: false,
          items: res.dialogs,
        }))
      )
      .catch(() => setDialogs((prev) => ({ ...prev, loading: false })));
  };
  useEffect(() => {
    onLoadDialogs();
  }, [messages]);

  useEffect(() => {
    if (state?.dialogId || dialogId) {
      data?.id && socket.emit("removeRoom", "message/" + data.id);
      setValue("id", state?.dialogId ?? dialogId);
    }
  }, [state?.dialogId, dialogId]);

  useEffect(() => {
    if (data?.id) {
      // viewMessages(data);
      if (data?.id == "general") {
        getMessagesGeneral(data)
          .then((res) =>
            setMessages((prev) => ({
              ...prev,
              loading: false,
              ...res,
            }))
          )
          .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
      } else {
        getMessages(data)
          .then((res) =>
            setMessages((prev) => ({
              ...prev,
              loading: false,
              ...res,
            }))
          )
          .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
      }
    }
  }, [data?.id]);

  useEffect(() => {
    if (data?.id) {
      socket.emit("createRoom", "message/" + data?.id);

      socket.on("message", (data) => {
        if (data) {
          setPrint(false);
          setMessages((prev) => ({
            ...prev,
            loading: false,
            items: [
              data,
              ...prev.items.map((e) => {
                if (e?.memberId) {
                  e.view = true;
                }
                return e;
              }),
            ],
          }));
        }
      });
      // socket.on("message/view/" + data.id, (data) => {
      //   setMessages((prev) => ({
      //     ...prev,
      //     loading: false,
      //     items: prev.items.map((e) => {
      //       if (e?.memberId && data == "client") {
      //         e.view = true;
      //       }
      //       return e;
      //     }),
      //   }));
      // });
      // socket.on("message/online/" + data.id, (online) => {
      //   setMessages((prev) => ({
      //     ...prev,
      //     user: {
      //       ...prev.user,
      //       online,
      //     },
      //   }));
      //   onLoadDialogs();
      // });
      // socket.on("message/print/admin/" + data.id, () => {
      //   setPrint(true);
      //   if (timer.current === 0) {
      //     timer.current = 1;
      //     setTimeout(() => {
      //       timer.current = 0;
      //       setPrint(false);
      //     }, 5000);
      //   }
      // });

      return () => {
        socket.off("message");
        // socket.off("message/view/" + data.id);
        // socket.off("message/print/admin/" + data.id);
      };
    }
  }, [data?.id]);

  // useEffect(() => {
  //   if (timer.current === 0 && data?.text?.length > 0) {
  //     timer.current = 1;
  //     socket.emit("message/print", { userId: data.id });
  //     setTimeout(() => {
  //       timer.current = 0;
  //     }, 3000);
  //   }
  // }, [data?.text]);

  const onNewMessage = useCallback(
    (text) => {
      if (data?.id === "general" || dialogId === "general") {
        createMessageGeneral({ ...data, text });
      } else {
        createMessage({ ...data, text });
      }

      reset({ id: data.id ?? dialogId });
    },
    [data, state, dialogId]
  );
  const onKeyPress1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setDialogs((prev) => ({
        ...prev,
        items: "",
      }))
      onLoadDialogs();
    }
  };
  if (dialogs.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true} className="h-100">
      <Meta title="Диалоги" />

      <section className="section-chats box p-0">
        <div className="section-chats-left">
          {/* <div className="bg-white py-3 px-0 position-sticky top-0">
            <h5 className="fw-7 px-3 d-flex justify-content-between align-items-center">
              Диалоги{" "}
              <Badge bg="secondary">{dialogs.items.length ?? 0}</Badge>
            </h5>
          </div> */}
          <form action="" className="mt-3 mb-3 mt-md-0">
            <input
              type="search"
              placeholder="Поиск пользователя"
              className="p-blue"
              onChange={e => setSearch(e.target.value)}
              onKeyPress={(e) => onKeyPress1(e)}
            />
          </form>
          <Link
            to="/dialogs/general"
            state={{ dialogId: "general" }}
            className="chats-list-item"
          >
            <div className="img">
              <img src="/images/general-chat.svg" width={40} />
            </div>
            <div className="text">
              <div className="d-flex align-items-baseline justify-content-between">
                <h6>Общий чат</h6>
              </div>
              <div className="last-user">
                <HiArrowLongRight />
                <span className="ms-2">Чат со всеми пользователями</span>
              </div>
            </div>
          </Link>
          {dialogs?.items && dialogs.items.length > 0 &&
            <ul className="chats-list">
              {dialogs.items.map((item, index) => (
                <li key={index}>
                  <DialogItem item={item} active={data?.id} />
                </li>
              ))}
            </ul>
          }
        </div>

        <div className="section-chats-main">
          {/* <div className="p-3 d-flex align-items-center justify-content-between">
            <div>
              <h5 className="fw-7 mb-0">Чат</h5>
              {messages?.user && (
                <p className="text-muted fs-07">
                  {print ? (
                    "Печатает сообщение..."
                  ) : messages?.user?.online?.status ? (
                    <span className="text-success">Онлайн</span>
                  ) : messages?.user?.online?.end ? (
                    "Был(-а) в сети " +
                    moment(messages?.user?.online?.end).fromNow()
                  ) : (
                    "Оффлайн"
                  )}
                </p>
              )}
            </div>
            <div>
              <IoEllipsisVertical size={22} />
            </div>
          </div> */}

          {!data?.id ? (
            <div className="h-100 d-flex align-items-center justify-content-center flex-column">
              <h3 className="text-center mb-3">Выберите диалог</h3>
              <p className="text-gray text-center">
                В данный момент нет диалогов. <br />
                Возможно вы не выбрали конкретный диалог.
              </p>
            </div>
          ) : messages.loading ? (
            <div className="h-100 d-flex align-items-center justify-content-center flex-column">
              <Loader />
            </div>
          ) : (
            <Chat
              data={messages}
              emptyText="Нет сообщений"
              onSubmit={(e) => onNewMessage(e)}
              onChange={(e) => setValue("text", e)}
            />
          )}
        </div>
        {messages?.user && (
          <Col
            md={3}
            className="p-0 list chat-container position-relative border-left"
          >
            <div className="bg-white border-bottom py-3 px-3 position-sticky top-0">
              <div className="d-flex align-items-center mb-3 justify-content-between">
                <div>
                  <p className="mb-1">
                    {messages?.user?.status ? (
                      <Badge className="badge-small" bg="success">
                        Активный
                      </Badge>
                    ) : (
                      <Badge className="badge-small" bg="danger">
                        Не подтвержден
                      </Badge>
                    )}
                  </p>
                  <h5>{messages?.user?.firstName ?? "Клиент"}</h5>
                </div>
                <div>
                  <Link
                    to={"/user/" + messages.user.id}
                    className="btn btn-light"
                  >
                    <IoChevronForwardOutline size={18} />
                  </Link>
                </div>
              </div>
              <p className="fs-08 mb-1">
                <span className="text-muted">id:</span>{" "}
                <b>{messages?.user?.id}</b>
              </p>
              {messages?.user?.email && (
                <p className="fs-08 mb-1">
                  <span className="text-muted">Email:</span>{" "}
                  <b>{messages.user.email}</b>
                </p>
              )}
              {messages?.user?.phone && (
                <p className="fs-08 mb-1">
                  <span className="text-muted">Телефон:</span>{" "}
                  <b>{messages.user.phone}</b>
                </p>
              )}
              {messages?.user?.birthday && (
                <p className="fs-08 mb-1">
                  <span className="text-muted">День рождения:</span>{" "}
                  <b>{moment(messages.user.birthday).calendar()}</b>
                </p>
              )}
              {messages?.user?.sex && (
                <p className="fs-08 mb-1">
                  <span className="text-muted">Пол:</span>{" "}
                  <b>{messages.user.sex == "man" ? "Мужской" : "Женский"}</b>
                </p>
              )}
            </div>
            <h5 className="fw-7 p-3 pb-3 d-flex justify-content-between align-items-center">
              Заказы
              <Badge bg="secondary">{messages?.order?.length ?? 0}</Badge>
            </h5>

            {messages?.order?.length > 0 &&
              messages.order.map((item) => <OrderItem {...item} />)}
          </Col>
        )}
      </section>
    </Container>
  );
};

export default Dialogs;
