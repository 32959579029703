import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getOrders = async (data) => {
  const response = await $authApi.get(apiRoutes.ORDERS, {
    params: data,
  });

  return response?.data;
};

const getOrder = async (data) => {
  const response = await $authApi.get(apiRoutes.ORDER, {
    params: data,
  });

  return response?.data;
};

const editOrder = async (data) => {
  const response = await $authApi.put(apiRoutes.ORDERS, data);
  return response?.data;
};

const createOrder = async (data) => {
  const response = await $authApi.post(apiRoutes.ORDERS, data);
  return response?.data;
};

const editOrderStatus = async (data) => {
  const response = await $authApi.put(apiRoutes.ORDER_STATUS, data);
  return response?.data;
};

const deleteOrder = async (id) => {
  const response = await $authApi.delete(apiRoutes.ORDERS, {
    data: { id },
  });
  return response?.data;
};

const createNote = async (data) => {
  const response = await $authApi.post(apiRoutes.ORDER_NOTE_CREATE, data);
  return response?.data;
};

const getNotes = async (data) => {
  const response = await $authApi.post(apiRoutes.ORDER_NOTES_GET, data);
  return response?.data;
};

export {
  getOrders,
  getNotes,
  getOrder,
  editOrder,
  deleteOrder,
  editOrderStatus,
  createOrder,
  createNote,
};