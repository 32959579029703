import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import {
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { deleteHistory, getHistories } from "../../services/history";

const Histories = () => {
  const user = useSelector((state) => state.auth.user);

  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [histories, setHistories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const historyColumns = [
    {
      name: "Браузер",
      selector: "brand",
    },
    {
      name: "Устройство",
      selector: "osName",
    },
    {
      name: "Версия",
      selector: "osVersion",
    },
    {
      name: "IP",
      selector: "ip",
    },
    { name: "Дата", cell: (row) => moment(row.createdAt).fromNow() },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between p-3 mb-3">
        <div>
          <h5 className="fw-7">
            {selected.length > 0 ? `Выбрано ${selected.length}` : "История"}
          </h5>
        </div>
        <div className="search-box mx-5">
          <Input
            ref={inputRef}
            placeholder="Найти"
            className="w-100"
            onChange={(e) => {
              searchParams.set("text", e);
              setSearchParams(searchParams);
            }}
            rightIcon={() => <IoSearchOutline size={22} />}
            defaultValue={searchParams.get("text")}
            rightIconClick={() => onSearch()}
            onKeyDown={(e) => e === "Enter" && onSearch()}
          />
          {searchParams.get("text")?.length > 0 && (
            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          )}
        </div>
        <div>
          <Button
            disabled={selected.length === 0}
            className="btn-light"
            onClick={() => setModalDelete({ show: true, id: false })}
          >
            <IoTrashOutline size={18} />
          </Button>
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getHistories(searchParams)
      .then((res) =>
        setHistories((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setHistories((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteHistory(id)
      .then(() => {
        getData();
        NotificationManager.success("История успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) => {
        err?.response?.data?.error ??
          NotificationManager.error("Ошибка при запросе");
      });
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteHistory(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные истории успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при запросе"
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (histories.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="История" />
      <section className="box p-0">
        <DataTable
          columns={historyColumns}
          onChange={(items) => setSelected(items)}
          data={histories.items}
          header={header}
          selectable
          pagination={histories.pagination}
        />
      </section>
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить?
      </CustomModal>
    </Container>
  );
};

export default Histories;