import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Container,
} from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoAdd,
  IoAddOutline,
  IoChevronBackOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";

import Textarea from "../../components/UI/Textarea";

import CustomModal from "../../components/utils/CustomModal";
import { generateUrl } from "../../helpers/all";
import { getImageURL } from "../../helpers/image";
import {
  createOption,
  createParam,
  createRegion,
  createServer,
  deleteOption,
  deleteParam,
  deleteRegion,
  deleteServer,
  editCategory,
  editOption,
  editOptionStatus,
  editParam,
  editParamStatus,
  editRegion,
  editRegionStatus,
  editServer,
  editServerStatus,
  getCategory,
} from "../../services/category";

function createOptionFun(data, idProp = "id", parentProp = "parent") {
  const tree = {};

  data.forEach((node) => {
    const id = node[idProp];
    tree[id] = { ...node, children: [] };
  });

  const rootNodes = [];

  data.forEach((node) => {
    const parentId = node[parentProp];
    if (parentId !== null) {
      const parentNode = tree[parentId];
      if (parentNode) {
        parentNode.children.push(tree[node[idProp]]);
      } else {
        rootNodes.push(tree[node[idProp]]);
      }
    } else {
      rootNodes.push(tree[node[idProp]]);
    }
  });

  return rootNodes;
}

const EditCategory = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);

  const [showDelete, setShowDelete] = useState({
    show: false,
    data: false,
    event: false,
  });

  const [showEditRegion, setShowEditRegion] = useState({
    show: false,
    data: false,
  });
  const [showCreateRegion, setShowCreateRegion] = useState({
    show: false,
    data: {
      title: "",
      priority: 0,
      status: true,
    },
  });
  const [showEditServer, setShowEditServer] = useState({
    show: false,
    data: false,
  });
  const [showCreateServer, setShowCreateServer] = useState({
    show: false,
    data: {
      title: "",
      priority: 0,
      status: true,
    },
  });

  const [showEditParam, setShowEditParam] = useState({
    show: false,
    data: false,
  });
  const [showCreateParam, setShowCreateParam] = useState({
    show: false,
    data: false,
  });

  const [showEditOption, setShowEditOption] = useState({
    show: false,
    data: false,
  });

  const [showCreateOption, setShowCreateOption] = useState({
    show: false,
    data: false,
  });

  // const [showEditOptionGeneral, setShowEditOptionGenerate] = useState({
  //   show: false,
  //   data: false,
  // });
  // const [showCreateOptionGeneral, setShowCreateOptionGenerate] = useState({
  //   show: false,
  //   data: false,
  // });

  // const [editImageProduct, setEditImageProduct] = useState({
  //   show: false,
  //   data: [],
  // });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const form = useWatch({ control });

  const getData = useCallback(() => {
    getCategory(categoryId)
      .then((res) => {
        res.media = getImageURL({ path: res.media, type: "category" });

        if (res?.regions?.length > 0) {
          let countServer = 0;
          res.regions.forEach((e) => (countServer += e.servers.length));
          res.countServer = countServer;
          res.regions = res.regions.sort(function (a, b) {
            return a.priority - b.priority;
          });
          res.regions = res.regions.map((e) => {
            e.servers = e.servers.sort(function (a, b) {
              return a.priority - b.priority;
            });
            return e;
          });
        }
        res.params =
          res?.params?.length > 0
            ? res.params
              .map((e) => {
                if (e?.options?.length > 0) {
                  e.options = createOptionFun(e.options).sort(function (
                    a,
                    b
                  ) {
                    return a.priority - b.priority;
                  });
                }
                return e;
              })
              .sort(function (a, b) {
                return a.priority - b.priority;
              })
            : [];
        if (!res?.uid && res.title) {
          res.uid = generateUrl(res.title);
        }
        reset(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [categoryId]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      await editCategory(data)
        .then(() => {
          NotificationManager.success("Каталог успешно обновлен");
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [categoryId]
  );

  // Регионы
  const onCreateRegion = useCallback(
    async (data) => {
      await createRegion({ ...data, id: form.id })
        .then(() => {
          NotificationManager.success("Регион успешно добавлен");
          getData();
          setShowCreateRegion({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditRegion = useCallback(
    (data) => {
      editRegion(data)
        .then(() => {
          NotificationManager.success("Регион успешно обновлен");
          getData();
          setShowEditRegion({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditRegionStatus = useCallback(
    (data) => {
      editRegionStatus(data)
        .then(() => {
          NotificationManager.success("Статус региона успешно обновлен");
          getData();
          setShowEditRegion({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onDeleteRegion = useCallback(
    (data) => {
      deleteRegion(data)
        .then(() => {
          NotificationManager.success("Регион успешно удален");
          getData();
          setShowDelete({ show: false, data: false, event: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при удалении"
          );
        });
    },
    [form]
  );

  // Сервер
  const onCreateServer = useCallback(
    ({ data, region }) => {
      createServer({ ...data, id: region.id })
        .then(() => {
          NotificationManager.success("Сервер успешно добавлен");
          getData();
          setShowCreateServer({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditServer = useCallback((data) => {
    editServer(data)
      .then(() => {
        NotificationManager.success("Сервер успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false, region: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditServerStatus = useCallback((data) => {
    editServerStatus(data)
      .then(() => {
        NotificationManager.success("Статус сервера успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteServer = useCallback((data) => {
    deleteServer(data)
      .then(() => {
        NotificationManager.success("Сервер успешно удален");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  // Параметры
  const onCreateParam = useCallback(
    (data) => {
      createParam({ ...data, id: form.id })
        .then(() => {
          NotificationManager.success("Параметр успешно добавлен");
          getData();
          setShowCreateParam({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditParam = useCallback((data) => {
    editParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно обновлен");
        getData();
        setShowEditParam({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditParamStatus = useCallback((data) => {
    editParamStatus(data)
      .then(() => {
        NotificationManager.success("Статус параметра успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteParam = useCallback((data) => {
    deleteParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно удален");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  // Опции
  const onCreateOption = useCallback((data) => {
    createOption(data.data)
      .then(() => {
        NotificationManager.success("Опция успешно добавлен");
        getData();
        setShowCreateOption({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditOption = useCallback((data) => {
    editOption(data)
      .then(() => {
        NotificationManager.success("Опция успешно обновлена");
        getData();
        setShowEditOption({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditOptionStatus = useCallback((data) => {
    editOptionStatus(data)
      .then(() => {
        NotificationManager.success("Статус опции успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteOption = useCallback((data) => {
    deleteOption(data)
      .then(() => {
        NotificationManager.success("Опция успешно удалена");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  function renderTree(option) {
    return (
      <>
        <div
          key={option.id}
          className={
            "d-flex align-items-center justify-content-between ps-2 ms-2 border-left fs-09"
          }
        >
          <a
            className={
              !option.parent
                ? "d-flex fs-09 fw-6 align-items-center"
                : "d-flex fs-09 text-muted align-items-center"
            }
            onClick={() => setShowEditOption({ show: true, data: option })}
          >
            <span>{option.title}</span>
          </a>
          <div className="d-flex align-items-center">
            <Form.Check
              type="switch"
              id={`custom-switch-${option.id}`}
              checked={option.status}
              onChange={(e) =>
                onEditOptionStatus({
                  id: option.id,
                  status: e.target.checked,
                })
              }
            />
            <a
              className="ms-2 text-danger"
              onClick={() =>
                setShowDelete({
                  show: true,
                  data: option,
                  event: onDeleteOption,
                })
              }
            >
              <IoTrashOutline />
            </a>
          </div>
        </div>

        {option.children?.length > 0 &&
          option.children.map((child) => (
            <React.Fragment key={child.id}>
              <div className="ps-2 ms-2 border-left">
                {renderTree(child)}
                <div className="d-flex align-items-center justify-content-between ps-2 ms-2 border-left fs-09">
                  <a
                    className="my-2 fs-08 d-flex align-items-center"
                    onClick={() =>
                      setShowCreateOption({
                        show: true,
                        data: child,
                      })
                    }
                  >
                    <IoAddOutline /> Добавить
                  </a>
                </div>
              </div>
            </React.Fragment>
          ))}
      </>
    );
  }

  if (loading) {
    return <Loader full />;
  }

  if (!form) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой игры нет</h3>
      </Info>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактировать игру" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link
              to="/games"
              className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
            >
              <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
            </Link>
          </div>
        </div>
        <Row>
          {/* <Col md={4} className="position-relative">
                <div className="position-sticky top-1">
                  <DragDropFile
                    file={data.media}
                    onChange={(e) =>
                      setEditImageProduct((info) => ({
                        show: !info.show,
                        data: e,
                      }))
                    }
                  />
                </div>
              </Col> */}
          <Col md={12} className="mb-4">
            <h3 className="mb-4">Редактировать игру</h3>
            <Row>
              <Col md={8}>
                <div className="mb-3">
                  <Input
                    defaultValue={form.title}
                    label="Название"
                    name="title"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Обязательное поле",
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Input
                    defaultValue={form.commission}
                    label="Комиссия"
                    name="commission"
                    min={0}
                    type="number"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    value={form.uid}
                    label="Ссылка"
                    name="uid"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите ссылку",
                      minLength: {
                        value: 2,
                        message: "Минимально 2 символа",
                      },
                      maxLength: {
                        value: 255,
                        message: "Максимально 255 символов",
                      },
                      pattern: {
                        value: /^[a-z0-9_]+$/,
                        message: "Неверный формат (Только a-z0-9_)",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <Textarea
                  defaultValue={form.desc}
                  label="Описание"
                  name="desc"
                  errors={errors}
                  rows={6}
                  register={register}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-self-end ">
              <Button
                className="mt-3"
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить изменения
              </Button>
            </div>
          </Col>
          <Col lg={12} xl={6}>
            <Card className="mb-4">
              <Card.Header className="d-flex align-items-center justify-content-between px-3">
                <h3>
                  Регионы{" "}
                  <Badge bg="secondary" className="fs-07">
                    {form?.regions?.length ?? 0}
                  </Badge>
                </h3>
                <Button
                  onClick={() =>
                    setShowCreateRegion((prev) => ({ ...prev, show: true }))
                  }
                  className="btn-primary-outline"
                >
                  <IoAdd />
                </Button>
              </Card.Header>
              <Card.Body className="py-0 px-3">
                {form.regions.length > 0 && (
                  <div className="text-muted fs-08 mb-2">Название</div>
                )}
                {form.regions.length > 0 &&
                  form.regions.map((e) => (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <a
                        className="d-flex fs-09 align-items-center"
                        onClick={() =>
                          setShowEditRegion({ show: true, data: e })
                        }
                      >
                        <MdOutlineEdit className="text-muted me-1" />
                        {e.priority && (
                          <span className="me-1">{e.priority}.</span>
                        )}
                        <span>{e.title}</span>
                      </a>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          defaultChecked={e.status}
                          onChange={(e2) =>
                            onEditRegionStatus({
                              id: e.id,
                              status: e2.target.checked,
                            })
                          }
                        />
                        <a
                          className="ms-2 text-danger"
                          onClick={() =>
                            setShowDelete({
                              show: true,
                              data: e,
                              event: onDeleteRegion,
                            })
                          }
                        >
                          <IoTrashOutline />
                        </a>
                      </div>
                    </div>
                  ))}
              </Card.Body>
              <hr />
              <Card.Header className="px-3">
                <h3>
                  Сервера{" "}
                  <Badge bg="secondary" className="fs-07">
                    {form?.countServer ?? 0}
                  </Badge>
                </h3>
              </Card.Header>
              <Card.Body className="py-0 px-3">
                {form.regions.length > 0 &&
                  form.regions.map((e) => (
                    <div className="mb-4">
                      <div className="mb-2 d-flex align-items-center justify-content-between">
                        <div className="fs-08 text-muted">{e.title}</div>
                        <Button
                          onClick={() =>
                            setShowCreateServer({
                              show: true,
                              region: e,
                            })
                          }
                          className="btn-light btn-xs"
                        >
                          <IoAdd />
                        </Button>
                      </div>
                      {e?.servers?.length > 0 ? (
                        e.servers.map((server) => (
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <a
                              className="d-flex fs-09 align-items-center"
                              onClick={() =>
                                setShowEditServer({
                                  show: true,
                                  data: server,
                                })
                              }
                            >
                              <MdOutlineEdit className="text-muted me-1" />
                              {server.priority && (
                                <span className="me-1">{server.priority}.</span>
                              )}
                              <span>{server.title}</span>
                            </a>
                            <div className="d-flex align-items-center">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                defaultChecked={server.status}
                                onChange={(e) =>
                                  onEditServerStatus({
                                    id: server.id,
                                    status: e.target.checked,
                                  })
                                }
                              />
                              <a
                                className="ms-2 text-danger"
                                onClick={() =>
                                  setShowDelete({
                                    show: true,
                                    data: server,
                                    event: onDeleteServer,
                                  })
                                }
                              >
                                <IoTrashOutline />
                              </a>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="fs-08 text-muted">Нет серверов</div>
                      )}
                    </div>
                  ))}
              </Card.Body>
            </Card>

            {/* <div className="position-sticky top-1">
              <DragDropFile
                file={data.media}
                onChange={(e) =>
                  setEditImageProduct((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
            </div> */}
          </Col>
          <Col lg={12} xl={6}>
            <Card>
              <Card.Header className="d-flex px-3 align-items-center justify-content-between">
                <h3>
                  Параметры{" "}
                  <Badge bg="secondary" className="fs-07">
                    {form?.params?.length ?? 0}
                  </Badge>
                </h3>
                <Button
                  onClick={() => setShowCreateParam({ show: true })}
                  className="btn-primary-outline"
                >
                  <IoAdd />
                </Button>
              </Card.Header>
              <Card.Body className="py-0 px-3">
                {form.params.length > 0 &&
                  form.params.map((e, index) => {
                    index++;
                    return (
                      <div className="mb-4">
                        <div className="mb-2 d-flex align-items-center justify-content-between">
                          <a
                            className="d-flex align-items-center"
                            onClick={() =>
                              setShowEditParam({
                                show: true,
                                data: e,
                              })
                            }
                          >
                            <MdOutlineEdit className="text-muted me-1" />
                            <span className="fw-7">{e.title}</span>
                          </a>
                          <div className="d-flex align-items-center">
                            <Form.Check
                              className="me-2"
                              type="switch"
                              id="custom-switch-param"
                              defaultChecked={e.status}
                              onChange={(e2) =>
                                onEditParamStatus({
                                  id: e.id,
                                  status: e2.target.checked,
                                })
                              }
                            />
                            <Button
                              onClick={() =>
                                setShowCreateOption({
                                  show: true,
                                  data: { ...e, main: true, paramId: e.id },
                                })
                              }
                              className="btn-light btn-xs me-2"
                            >
                              <IoAdd />
                            </Button>
                            <Button
                              onClick={() =>
                                setShowDelete({
                                  show: true,
                                  data: e,
                                  event: onDeleteParam,
                                })
                              }
                              className="btn-danger-outline btn-xs"
                            >
                              <IoTrashOutline />
                            </Button>
                          </div>
                        </div>
                        {e?.options?.length > 0 ? (
                          e.options.map((option, index) => {
                            index++;

                            return (
                              <>
                                {/* <div className="d-flex align-items-center justify-content-between py-1 ps-2 fw-6">
                                  <a
                                    className="d-flex fs-09 align-items-center"
                                    onClick={() =>
                                      setShowEditOption({
                                        show: true,
                                        data: option,
                                      })
                                    }
                                  >
                                    {!option?.parent && (
                                      <MdOutlineEdit className="text-muted me-1" />
                                    )}
                                    <span>{option.title}</span>
                                  </a>
                                  <div className="d-flex align-items-center">
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      checked={option.status}
                                      onChange={(e) =>
                                        onEditOptionStatus({
                                          id: option.id,
                                          status: e.target.checked,
                                        })
                                      }
                                    />

                                    <a
                                      className="ms-2 text-danger"
                                      onClick={() =>
                                        setShowDelete({
                                          show: true,
                                          data: option,
                                          event: onDeleteOption,
                                        })
                                      }
                                    >
                                      <IoTrashOutline />
                                    </a>
                                  </div>
                                </div> */}
                                {renderTree(option)}
                                {/* {option?.children?.length > 0 &&
                                  option.children.map((item, index) => {
                                    index++;
                                    return (
                                      <>
                                        <div
                                          className={
                                            "d-flex align-items-center justify-content-between py-1 ps-4 ms-2 border-left fs-09 text-muted"
                                          }
                                        >
                                          <a
                                            className="d-flex fs-09 align-items-center"
                                            onClick={() =>
                                              setShowEditOption({
                                                show: true,
                                                data: item,
                                              })
                                            }
                                          >
                                            <span
                                              bg={
                                                item.status
                                                  ? "success"
                                                  : "secondary"
                                              }
                                              className="me-1"
                                            >
                                              {index}.
                                            </span>

                                            <span>{item.title}</span>
                                          </a>
                                          <div className="d-flex align-items-center">
                                            <Form.Check
                                              type="switch"
                                              id="custom-switch"
                                              checked={item.status}
                                              onChange={(e) =>
                                                onEditOptionStatus({
                                                  id: item.id,
                                                  status: e.target.checked,
                                                })
                                              }
                                            />

                                            <a
                                              className="ms-2 text-danger"
                                              onClick={() =>
                                                setShowDelete({
                                                  show: true,
                                                  data: item,
                                                  event: onDeleteOption,
                                                })
                                              }
                                            >
                                              <IoTrashOutline />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="border-left ps-2 ms-2">
                                          {item?.children?.length > 0 &&
                                            item.children.map(
                                              (child, index) => {
                                                index++;
                                                return (
                                                  <div
                                                    className={
                                                      "d-flex align-items-center justify-content-between py-1 ps-2 ms-2 border-left fs-09 text-muted"
                                                    }
                                                  >
                                                    <a
                                                      className="d-flex fs-09 align-items-center"
                                                      onClick={() =>
                                                        setShowEditOption({
                                                          show: true,
                                                          data: child,
                                                        })
                                                      }
                                                    >
                                                      <span
                                                        bg={
                                                          child.status
                                                            ? "success"
                                                            : "secondary"
                                                        }
                                                        className="me-1"
                                                      >
                                                        {index}.
                                                      </span>

                                                      <span>{child.title}</span>
                                                    </a>
                                                    <div className="d-flex align-items-center">
                                                      <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={child.status}
                                                        onChange={(e) =>
                                                          onEditOptionStatus({
                                                            id: child.id,
                                                            status:
                                                              e.target.checked,
                                                          })
                                                        }
                                                      />

                                                      <a
                                                        className="ms-2 text-danger"
                                                        onClick={() =>
                                                          setShowDelete({
                                                            show: true,
                                                            data: child,
                                                            event:
                                                              onDeleteOption,
                                                          })
                                                        }
                                                      >
                                                        <IoTrashOutline />
                                                      </a>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}

                                          <div className="d-flex align-items-center justify-content-between ps-2 ms-2 border-left fs-09">
                                            <a
                                              className="my-2 fs-08 d-flex align-items-center"
                                              onClick={() =>
                                                setShowCreateOption({
                                                  show: true,
                                                  data: item,
                                                  param: e,
                                                })
                                              }
                                            >
                                              <IoAddOutline /> Добавить
                                            </a>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })} */}
                                {!option?.parent && (
                                  <div className="d-flex align-items-center justify-content-between ps-2 ms-2 border-left fs-09">
                                    <a
                                      className="my-2 fs-08 d-flex align-items-center"
                                      onClick={() =>
                                        setShowCreateOption({
                                          show: true,
                                          data: option,
                                          param: e,
                                        })
                                      }
                                    >
                                      <IoAddOutline /> Добавить
                                    </a>
                                  </div>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <div className="fs-08 text-muted">Нет значений</div>
                        )}
                      </div>
                    );
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <CustomModal
          title="Новый регион"
          show={showCreateRegion.show}
          setShow={(e) => setShowCreateRegion({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() =>
                  setShowCreateRegion({ show: false, data: false })
                }
              >
                Отмена
              </Button>
              <Button onClick={() => onCreateRegion(showCreateRegion.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              onChange={(e) =>
                setShowCreateRegion((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              placeholder={form?.regions?.length ? form.regions.length + 1 : 0}
              onChange={(e) =>
                setShowCreateRegion((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-region-create"
            type="switch"
            defaultChecked={true}
            onChange={(e) =>
              setShowCreateRegion((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
        </CustomModal>
        <CustomModal
          title="Редактирование региона"
          show={showEditRegion.show}
          setShow={(e) => setShowEditRegion({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowEditRegion({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onEditRegion(showEditRegion.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              defaultValue={showEditRegion.data.title}
              onChange={(e) =>
                setShowEditRegion((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              defaultValue={showEditRegion.data.priority}
              onChange={(e) =>
                setShowEditRegion((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-region-edit"
            type="switch"
            defaultChecked={showEditRegion.data.status}
            onChange={(e) =>
              setShowEditRegion((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
        </CustomModal>
        <CustomModal
          title={
            showCreateServer?.region?.title
              ? showCreateServer.region.title + " - Новый сервер"
              : "Новый сервер"
          }
          show={showCreateServer.show}
          setShow={(e) => setShowCreateServer({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() =>
                  setShowCreateServer({ show: false, data: false })
                }
              >
                Отмена
              </Button>
              <Button
                onClick={() =>
                  onCreateServer({
                    data: showCreateServer.data,
                    region: showCreateServer.region,
                  })
                }
              >
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              onChange={(e) =>
                setShowCreateServer((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              placeholder={form?.countServer + 1}
              onChange={(e) =>
                setShowCreateServer((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-server-create"
            type="switch"
            defaultChecked={true}
            onChange={(e) =>
              setShowCreateServer((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
        </CustomModal>
        <CustomModal
          title="Редактирование сервера"
          show={showEditServer.show}
          setShow={(e) => setShowEditServer({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowEditServer({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onEditServer(showEditServer.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              defaultValue={showEditServer.data.title}
              onChange={(e) =>
                setShowEditServer((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              defaultValue={showEditServer.data.priority}
              onChange={(e) =>
                setShowEditServer((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-region-edit"
            type="switch"
            defaultChecked={showEditServer.data.status}
            onChange={(e) =>
              setShowEditServer((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
        </CustomModal>

        <CustomModal
          title="Новый параметр"
          show={showCreateParam.show}
          setShow={(e) => setShowCreateParam({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowCreateParam({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onCreateParam(showCreateParam.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              placeholder={form?.params?.length + 1}
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-param-create"
            type="switch"
            className="mb-2"
            defaultChecked={true}
            onChange={(e) =>
              setShowCreateParam((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
          <Form.Check
            label="Валюта"
            className="mb-2"
            id="currency-param-create"
            type="switch"
            defaultChecked={false}
            onChange={(e) =>
              setShowCreateParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, currency: e.target.checked }
                    : { currency: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Никнейм"
            className="mb-2"
            id="nickname-param-create"
            type="switch"
            defaultChecked={false}
            onChange={(e) =>
              setShowCreateParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, nickname: e.target.checked }
                    : { nickname: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Покупка всегда по 1"
            className="mb-2"
            id="one-param-create"
            type="switch"
            defaultChecked={false}
            onChange={(e) =>
              setShowCreateParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, one: e.target.checked }
                    : { one: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Скрыть\Показать сервера"
            id="server-param-create"
            type="switch"
            onChange={(e) =>
              setShowCreateParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, serverView: e.target.checked }
                    : { serverView: e.target.checked },
                }
              }))
            }
          />
        </CustomModal>

        <CustomModal
          title="Редактирование параметра"
          show={showEditParam.show}
          setShow={(e) => setShowEditParam({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowEditParam({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onEditParam(showEditParam.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              defaultValue={showEditParam.data.title}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              defaultValue={showEditParam.data.priority}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-param-edit"
            className="mb-2"
            type="switch"
            defaultChecked={showEditParam.data.status}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
          <Form.Check
            label="Валюта"
            className="mb-2"
            id="currency-param-create"
            type="switch"
            defaultChecked={!!showEditParam.data.data?.currency}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, currency: e.target.checked }
                    : { currency: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Никнейм"
            className="mb-2"
            id="nickname-param-create"
            type="switch"
            defaultChecked={!!showEditParam.data.data?.nickname}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, nickname: e.target.checked }
                    : { nickname: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Покупка всегда по 1"
            id="one-param-create"
            className="mb-2"
            type="switch"
            defaultChecked={!!showEditParam.data.data?.one}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, one: e.target.checked }
                    : { one: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Показать\Скрыть сервера"
            id="server-param-edit"
            className="mb-2"
            type="switch"
            defaultChecked={!!showEditParam.data.data?.serverView}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, serverView: e.target.checked }
                    : { serverView: e.target.checked },
                },
              }))
            }
          />
          <Form.Check
            label="Автовыдача"
            id="auto"
            type="switch"
            defaultChecked={!!showEditParam.data.data?.auto}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev?.data?.data
                    ? { ...prev.data.data, auto: e.target.checked }
                    : { auto: e.target.checked },
                },
              }))
            }
          />
        </CustomModal>

        <CustomModal
          title="Новая опция"
          show={showCreateOption.show}
          setShow={(e) => setShowCreateOption({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() =>
                  setShowCreateOption({ show: false, data: false })
                }
              >
                Отмена
              </Button>
              <Button
                onClick={() =>
                  onCreateOption({
                    data: showCreateOption.data,
                  })
                }
              >
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              onChange={(e) =>
                setShowCreateOption((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              onChange={(e) =>
                setShowCreateOption((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          {!showCreateOption.data?.parent && (
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    label="Минимально"
                    type="number"
                    onChange={(e) =>
                      setShowCreateOption((prev) => ({
                        ...prev,
                        data: {
                          ...prev.data,
                          data: prev?.data?.data
                            ? { ...prev.data.data, min: e }
                            : { min: e },
                        },
                      }))
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    label="Максимально"
                    type="number"
                    onChange={(e) =>
                      setShowCreateOption((prev) => ({
                        ...prev,
                        data: {
                          ...prev.data,
                          data: prev?.data?.data
                            ? { ...prev.data.data, max: e }
                            : { max: e },
                        },
                      }))
                    }
                  />
                </div>
              </Col>
              <Col md={12}>
                <Form.Check
                  label="Показывать в описании"
                  id="desc-option-edit"
                  type="switch"
                  onChange={(e) =>
                    setShowCreateOption((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        data: prev?.data?.data
                          ? { ...prev.data.data, desc: e.target.checked }
                          : { desc: e.target.checked },
                      },
                    }))
                  }
                />
              </Col>
            </Row>
          )}
        </CustomModal>
        <CustomModal
          title="Редактирование опции"
          show={showEditOption.show}
          setShow={(e) => setShowEditOption({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowEditOption({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onEditOption(showEditOption.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              defaultValue={showEditOption.data.title}
              onChange={(e) =>
                setShowEditOption((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              defaultValue={showEditOption.data.priority}
              onChange={(e) =>
                setShowEditOption((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-option-edit"
            className="mb-3"
            type="switch"
            defaultChecked={showEditOption.data.status}
            onChange={(e) =>
              setShowEditOption((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Минимально"
                  type="number"
                  defaultValue={showEditOption.data.data?.min}
                  onChange={(e) =>
                    setShowEditOption((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        data: prev?.data?.data
                          ? { ...prev.data.data, min: e }
                          : { min: e },
                      },
                    }))
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Максимально"
                  type="number"
                  defaultValue={showEditOption.data.data?.max}
                  onChange={(e) =>
                    setShowEditOption((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        data: prev?.data?.data
                          ? { ...prev.data.data, max: e }
                          : { max: e },
                      },
                    }))
                  }
                />
              </div>
            </Col>
          </Row>
          {!showEditOption.data?.parent && (
            <Row>
              <Col md={12}>
                <Form.Check
                  label="Показывать в описании"
                  id="desc-option-edit"
                  type="switch"
                  defaultChecked={showEditOption.data.data?.desc}
                  onChange={(e) =>
                    setShowEditOption((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        data: prev?.data?.data
                          ? { ...prev.data.data, desc: e.target.checked }
                          : { desc: e.target.checked },
                      },
                    }))
                  }
                />
              </Col>
              <Col md={12}>
                <Form.Check
                  label="Применить настройки дочерним опциям"
                  id="copy-option-edit"
                  type="switch"
                  defaultChecked={showEditOption.data.data?.copy}
                  onChange={(e) =>
                    setShowEditOption((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        data: prev?.data?.data
                          ? { ...prev.data.data, copy: e.target.checked }
                          : { copy: e.target.checked },
                      },
                    }))
                  }
                />
              </Col>
            </Row>
          )}
        </CustomModal>

        <CustomModal
          title="Подтвердите удаление"
          show={showDelete.show}
          setShow={(e) => setShowDelete({ show: e, data: false, event: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() =>
                  setShowDelete({ show: false, data: false, event: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  showDelete.event && showDelete.event(showDelete.data)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить данный элемент? Восстановить данные будет не
          возможно.
        </CustomModal>
      </section>
    </Container>
  );
};

export default EditCategory;
