import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import AuthRoute from "../layouts/AuthRoute";
import Admin from "../pages";
import AdminRouter from "./AdminRouter";
import Recovery from "../pages/auth/Recovery";
import NewPassword from "../pages/auth/NewPassword";
import Home from "../pages/Home";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route index path="/" element={<Admin />} />
      <Route path="recovery" element={<Recovery />} />
      <Route path="new-password" element={<NewPassword />} />
      <Route
        path="/*"
        element={
          <AuthRoute>
            <AdminRouter />
          </AuthRoute>
        }
      />
    </Route>
  )
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
