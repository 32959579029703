import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  IoCreateOutline,
  IoEllipsisVertical,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteSale, getSales } from "../../services/sale";
import moment from "moment";
import { Badge, Card, Col, Dropdown, Row, Container } from "react-bootstrap";
import { getImageURL } from "../../helpers/image";

const Sales = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sales, setSales] = useState({
    loading: true,
    home: [],
    ad: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const saleColumns = [
    {
      name: "Заголовок",
      selector: "title",
    },
    {
      name: "Кнопка",
      selector: "btn",
      cell: (row) => (row?.btn?.length > 0 ? row.btn : "Перейти"),
    },
    {
      name: "Расположение",
      cell: () => "Главная",
    },
    {
      name: "Время публикации",
      cell: (row) => (
        <span>
          <b className="me-2">{moment(row.createdAt).format("kk:mm")}</b>
          {moment(row.createdAt).format("DD.MM.YYYY")}
        </span>
      ),
    },
    {
      name: "Статус",
      cell: (row) =>
        row.status ? (
          <Badge bg="success">Опубликовано</Badge>
        ) : (
          <Badge bg="secondary">Архив</Badge>
        ),
    },
    {
      width: "100px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/sale/" + row.id} className="me-4">
            <IoCreateOutline size={22} />
          </Link>
          <a
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return selected.length > 0 ? (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Выбрано {selected.length}</h5>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <Button
              className="btn-light"
              onClick={() => setModalDelete({ show: !modalDelete.show })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="fw-7 me-3">Рекламные баннеры</h5>
        <Link to="/sales/create?type=1" className="btn-primary-outline">
          Добавить баннер
        </Link>
      </div>
    );
  }, [selected]);

  const getData = useCallback(async () => {
    getSales(searchParams)
      .then(
        (res) =>
          res &&
          setSales((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setSales((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteSale(id)
      .then(() => {
        getData();
        NotificationManager.success("Баннер успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteSale(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные баннеры успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (sales.loading) {
    return <Loader full />;
  }

  if (!sales?.home?.items && !sales?.ad?.items) {
    return (
      <Container fluid={true}>
        <Meta title="Баннеры" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Пока баннеров нет</h3>
          <Link className="mt-3 btn btn-primary" to="/sales/create">
            Добавить баннер
          </Link>
        </Info>
      </Container>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Баннеры" />
      <section className="box">
        {sales.home.items.length > 0 ? (
          <div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <h5 className="fw-7">Баннеры на главной странице</h5>
              <Link to="/sales/create" className="btn-primary-outline">
                Добавить баннер
              </Link>
            </div>
            <Row>
              {sales.home.items.map((e) => (
                <Col md={4}>
                  <figure class="sale-mini">
                    <img
                      src={getImageURL({
                        path: e.media,
                        size: "full",
                        type: "sale",
                      })}
                      alt={e.title}
                    />
                    <figcaption className="d-flex justify-content-between align-items-center">
                      <Link class="text-transfer" to={"/sale/" + e.id}>
                        {e.title.length > 0 ? e.title : "Заголовок"}
                      </Link>
                      <div>
                        <Dropdown className="d-flex align-items-center">
                          <Dropdown.Toggle
                            as={React.forwardRef(
                              ({ children, onClick }, ref) => (
                                <Link
                                  ref={ref}
                                  className="py-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onClick(e);
                                  }}
                                >
                                  <IoEllipsisVertical size={20} />
                                </Link>
                              )
                            )}
                          />
                          <Dropdown.Menu align="end">
                            <Dropdown.Item as={Link} to={"/sale/" + e.id}>
                              Редактировать
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                setModalDelete({
                                  show: !modalDelete.show,
                                  id: e.id,
                                })
                              }
                            >
                              Удалить
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </figcaption>
                  </figure>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div>
            <div className="my-5">
              <h3>Основных баннеров нет</h3>
              <div className="d-flex justify-content-center">
                <Link className="mt-3 btn btn-primary" to="/sales/create">
                  Добавить баннер
                </Link>
              </div>
            </div>
          </div>
        )}

        <CustomModal
          title={
            selected.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить баннер(-ы)?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Sales;
