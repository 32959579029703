const roles = [
  {
    value: 1,
    title: "Главный Администратор",
    desc: "",
    color: "#ff5630",
  },
  {
    value: 2,
    title: "Администратор",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 3,
    title: "Старший модератор",
    desc: "",
    color: "#3366ff",
  },
  {
    value: 4,
    title: "Модератор",
    desc: "",
    color: "#1d9330",
  },
];

const isRole = ({ role }) => {
  let roleInfo = roles.find((e) => e.value === role);
  if (!roleInfo) {
    return "Роль не найдена";
  }

  return { role: roleInfo };
};

export { roles, isRole };
