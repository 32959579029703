import { createSlice } from "@reduxjs/toolkit";
import notification1 from "../../assets/audio/notification-1.mp3";

const initialState = {
  message: 0,
  order: 0,
  notification: 0,
  task: 0
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotification: (state) => {
      state.task = 0
      state.message = 0
      state.notification = 0
      state.order = 0
    },
    setNotification: (state, action) => {
      state.notification = action.payload.notification ?? 0
      state.message = action.payload.message ?? 0
      state.task = action.payload.task ?? 0
      state.order = action.payload.order ?? 0
    },
    updateNotification: (state, action) => {
      const audio = new Audio(notification1);
      if (action?.payload?.message) {
        if (action.payload.message == -1) {
          state.message = 0;
        } else {
          state.message++;
          audio.play();
        }
      }
      if (action?.payload?.order) {
        if (action.payload.order == -1) {
          state.order = 0;
        } else {
          state.order++;
          audio.play();
        }
      }
      if (action?.payload?.task) {
        if (action.payload.task == -1) {
          state.task = 0;
        } else {
          state.task++;
          audio.play();
        }
      }
      if (action?.payload?.notification) {
        if (action.payload.notification == -1) {
          state.notification = 0;
        } else {
          state.notification++;
          audio.play();
        }
      }
    },
  },
});

export const { updateNotification, clearNotification, setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;