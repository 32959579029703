import React, { memo } from "react";
import Loader from "./Loader";

const Button = memo(
  ({ isLoading, children, className = "btn-primary", ...props }) => {
    return (
      <button className={"btn " + className} {...props}>
        {isLoading ? (
          <Loader
            className="loading"
            size={20}
            color={
              className == "btn-primary" ||
              className == "btn-blue" ||
              className == "btn-danger"
                ? "#FFFFFF"
                : false
            }
          />
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
